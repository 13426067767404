import BGPH1 from '../../assets/images/backgrounds/ContactUs.png'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { postContacts } from '../../Services/API/Post'

import MAP1 from '../../assets/images/contact/map1.png'
import MAP2 from '../../assets/images/contact/map2.png'


const Contact = () => {

    const [msg, setmsg] = React.useState(null)

    const [firstName, setfirstName] = useState("");
    const [subject, setsubject] = useState("");
    const [email, setemail] = useState("");
    const [message, setmessage] = useState("");
    const [mobile, setmobile] = useState("");

    const [errfirstName, seterrfirstName] = useState(false);
    const [errsubject, seterrsubject] = useState(false);
    const [errmessage, seterrmessage] = useState(false);
    const [erremail, seterremail] = useState(false);
    const [errmobile, seterrmobile] = useState("");


    React.useEffect(() => {
        if (msg !== null) {
            setTimeout(() => {
                setmsg(null);
            }, 6000);
        }

    }, [msg])

    const contactUs = (event) => {

        const phoneRegex =
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;


        event.preventDefault();

        const obj = {
            name: firstName,
            email: email,
            subject: subject,
            message: message,
            contactNumber: mobile,
        };

        console.log("obj", obj);

        if (firstName === "") {
            seterrfirstName(true);
        }
        if (subject === "") {
            seterrsubject(true);
        }
        if (email === "") {
            seterremail(true);
        }

        if (message === "") {
            seterrmessage(true);
        }

        if (!phoneRegex.test(mobile)) {
            if (mobile === "") {
                seterrmobile("This field is required");
            } else {
                seterrmobile("Please enter a valid phone number");
            }
        }


        if (
            firstName !== "" &&
            email !== "" &&
            subject !== "" &&
            message !== "" &&
            mobile !== "" &&
            phoneRegex.test(mobile)
        ) {
            postContacts(obj)
                .then((res) => {
                    console.log("res", res);
                    setmsg("Your enquiry has been successfully submitted")

                    setfirstName("");
                    setemail("")
                    setsubject("")
                    setmessage("")

                    // alert("Submited..!!")
                    // this.setState(
                    //   {
                    //     name: "",
                    //     email: "",
                    //     contact:"",
                    //     memberNo:"",
                    //     webSite: "",
                    //     message: "",
                    //     toaster: true,
                    //     msg: "Your enquiry has been successfully submitted",
                    //   }
                    //   //   () => {
                    //   //     setTimeout(this.setState({toaster: false}),4000);
                    //   //   }
                    // );
                })
                .catch((error) => {
                    console.log("error", error);
                    // setmsg(error.response.data.message)
                    // setTimeout(setmsg(null),4000);
                    // this.setState({});
                });

        }



    };

    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Contact</span></li> */}
                    </ul>
                    <h2>Contact us</h2>
                </div>
            </section>


            <section className="contact-one pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="contact-one__content">
                                <div className="block-title">

                                    <h2 className="block-title__title">Feel free to get
                                        in touch</h2>
                                </div>
                                <p className="contact-one__text">Have questions or ready to explore how Glorious Support Services can make a difference for you or your loved ones? Contact us today, and let's start a conversation about personalized care and support.
                                </p>


                            </div>
                        </div>


                        <div className="col-lg-8">
                            <form className="form-one contact-form-validated" onSubmit={contactUs} >
                                <div className="row row-gutter-y-20 row-gutter-x-20">
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Full name" name="name"
                                            value={firstName}
                                            onChange={(e) => {
                                                setfirstName(e.target.value);
                                                seterrfirstName(false);
                                            }}
                                        />
                                        {errfirstName && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="email" placeholder="Email address" name="email"
                                            value={email}
                                            onChange={(e) => {
                                                setemail(e.target.value);
                                                seterremail(false);
                                            }} />
                                        {erremail && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Phone number" name="phone"
                                            value={mobile}
                                            onChange={(e) => {
                                                setmobile(e.target.value);
                                                seterrmobile("");
                                            }}
                                        />
                                        {errmobile && (
                                            <div className="error" style={{ color: "red" }}>
                                                {errmobile}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Subject" name="subject"
                                            value={subject}
                                            onChange={(e) => {
                                                setsubject(e.target.value);
                                                seterrsubject(false);
                                            }}
                                        />
                                        {errsubject && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        <textarea placeholder="Message" name="message"
                                            value={message}
                                            onChange={(e) => {
                                                setmessage(e.target.value);
                                                seterrmessage(false);
                                            }}
                                        ></textarea>
                                        {errmessage && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    {msg && (
                                        <span className="error" style={{ color: "green" }} >{msg}</span>
                                    )}
                                    <div className="col-md-12 d-flex align-items-center">

                                        <button type="submit" className="thm-btn">Send Message</button>

                                        {/* <h6 className="contact-info-one__link pl-20 pr-20">Or</h6> */}

                                        {/* <button type="submit" className="thm-btn2">Schedule Appointment</button> */}
                                        {/* <a href='https://calendly.com/learnexcel/30min' className='thm-btn' target='_blank'>Schedule An Appointment</a> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>


            <section className="contact-info-one">
                <div className="">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-4 col-sm-12">
                            <div className="contact-info-one__item">
                                <div className="contact-info-one__icon">
                                    <i className="icon-telephone"></i>
                                </div>
                                <div className="contact-info-one__content">
                                    <p className="contact-info-one__text">Have any question?</p>
                                    <a className="contact-info-one__link" href="tel:0413599838" >0413 599 838</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="contact-info-one__item">
                                <div className="contact-info-one__icon">
                                    <i className="icon-email"></i>
                                </div>
                                <div className="contact-info-one__content">
                                    <p className="contact-info-one__text">Write us email</p>
                                    <a className="contact-info-one__link" href="mailto:info@glorioussupportservices.com.au">info@glorioussupportservices.com.au</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="contact-info-one__item">
                                <div className="contact-info-one__icon">
                                    <i className="icon-pin"></i>
                                </div>
                                <div className="contact-info-one__content">
                                    <p className="contact-info-one__text">Visit anytime</p>
                                    <a className="contact-info-one__link" href="#">48 Charming Loop, Officer 3809</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* <a href="https://maps.app.goo.gl/A5htM42WE5LKEsVY8" target="_blank" style={{ cursor: "pointer" }}>

                <div class="about-two__image2 d-none d-sm-block">
                    <img src={MAP1} alt="" style={{ width: "100%" }} />

                </div>

                <div class="about-two__image2 d-block d-sm-none">
                    <img src={MAP2} alt="" style={{ width: "100%" }} />

                </div>

            </a> */}


            <div className="google-map__default">
                  </div>   <iframe title="template google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16293.658527543103!2d145.38212392333284!3d-38.069632704365766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad61b8d02610881%3A0x5b705e9f84636fc9!2s48%20Charming%20Lp%2C%20Officer%20VIC%203809%2C%20Australia!5e0!3m2!1sen!2slk!4v1732691673827!5m2!1sen!2slk" width="100%" height="500" className="map__default w-full" allowfullscreen></iframe>
       

            {/* <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}

        </>
    )
}

export default Contact